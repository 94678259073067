@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .wallpaper_city_bg {
    @apply h-screen bg-gradient-to-t from-[#ffbda3] to-[#d797b9] relative;
  }
  .wallpaper_sky_bg {
    @apply h-screen bg-gradient-to-t from-[#bee4f9] to-[#79caf9] relative;
  }

  .wallpaper_city_taskbar {
    @apply bg-amber-800/40;
  }
  .wallpaper_city_taskbar_start {
    @apply hover:bg-[#644035] hover:text-[#fdaf60];
  }

  .wallpaper_sky_taskbar {
    @apply bg-sky-800/60;
  }
  .wallpaper_sky_taskbar_start {
    @apply hover:bg-[#1d3557] hover:text-[#9dbeff];
  }

  .icon_hover {
    @apply hover:bg-sky-600/10 hover:border-sky-200/50 hover:border-[1px] hover:cursor-default hover:px-1;
  }

  .windows_btns {
    @apply h-3 hover:scale-[1.2] transition-all hover:cursor-pointer;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 30px solid black;
}

.react-resizable-handle-n,
.react-resizable-handle-s,
.react-resizable-handle-w,
.react-resizable-handle-e {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  z-index: 2;
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  cursor: ns-resize;
  height: 10px;
  width: 100%;
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  cursor: ew-resize;
  height: 100%;
  width: 10px;
}

.react-resizable-handle-n {
  top: -5px;
}

.react-resizable-handle-s {
  bottom: -5px;
}

.react-resizable-handle-w {
  left: -5px;
}

.react-resizable-handle-e {
  right: -5px;
}
